<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container fluid>
            <!-- Entry Info section -->
            <v-row>
              <v-col cols="12" class="pa-0">
                <span class="display-2">
                  <v-icon left class="pb-1">mdi-account-circle</v-icon
                  >{{ $vuetify.lang.t("$vuetify.newSupplierInfo") }}</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col></v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" lg="12" md="3" sm="6">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.userName')"
                  outlined
                  clearable
                  v-model="myObj.name"
                  :rules="generalRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" lg="12" md="3" sm="6">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.contactPersonPhone')"
                  outlined
                  clearable
                  v-model="myObj.mobile"
                  :rules="generalRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" lg="12" md="3" sm="6">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.email')"
                  outlined
                  clearable
                  v-model="myObj.email"
                  :rules="generalRule"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-8">
        <v-spacer />
        <v-btn color="red darken-2" outlined @click="cancelForm">{{
          $vuetify.lang.t("$vuetify.cancel")
        }}</v-btn>
        <v-btn color="green darken-2" @click="validate">{{
          $vuetify.lang.t("$vuetify.save")
        }}</v-btn>
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: { dialog: Boolean, myObj: Object, deleteObj: Object },
  data() {
    return {
      valid: false,
       generalRule: [(v) => !!v || "this field is required"],
    };
  },
  methods: {
    cancelForm() {
      this.resetValidation();
      this.$emit("update:dialog", false);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    validate(){
        this.$refs.form.validate() == true
        ? this.$emit("handledata", this.myObj)
        : console.log("false");
    }
  },
};
</script>